import React, { useEffect, useState } from "react";
import { withPrefix } from "gatsby"

import USA from "../img/svg/usa_base.svg";
import statenames from "../data/state_abbrev_to_name.json";

import "../styles/usamap.css";

export const USAMapLegendColor = ({ color }) => {
  return <div
  style={{
    display: 'inline-block',
    height: 15,
    width: 15,
    backgroundColor: color
  }}
  className="usa-map-legend-color" />
};

export const USAMapStateSelect = ({ selectedStateFromMap }) => {
  const [SelectedStateAbbrev, setSelectedStateAbbrev] = useState(null);
  return <div className="usa-map-select">
    <select 
      value={selectedStateFromMap}
      onBlur={(e) => setSelectedStateAbbrev(e.target.value) }>
      <option>Select a state to view</option>
      {
        Object.keys(statenames).map((statename) => <option key={statename} value={statename}>{statenames[statename]}</option>)
      }
    </select>
    <button onClick={() => SelectedStateAbbrev ? window.location = withPrefix(`/reforms/${SelectedStateAbbrev}/`) : null}>Go</button>
  </div>
}

const USAMap = (props) => {
  // const [HoveredState, setHoveredState] = useState(null);
  // const [SelectedStateFromMap, setSelectedStateFromMap] = useState(null);
  useEffect(() => {
    const updateMap = () => {      
      for (const state of Object.keys(statenames)) {
        const elem = document.getElementById(state);
        const currStateData = props.stateData.filter((stateDatum) => props.dataFilter(stateDatum, state));
        let defaultStateColor = "#D3D3D3";
        if (currStateData.length === 1) {
          defaultStateColor = props.legendMapFn ? 
            props.legendMapFn(props.legend, currStateData[0])
            :
            props.legend[currStateData[0].data[props.statusFieldName]];
        }
        if (elem) {
          elem.style.stroke = "";
          elem.style.strokeWidth = "";
          if (state === props.SelectedStateFromMap) {
            elem.style.stroke = "orange";
            elem.style.strokeWidth = 5;
          }
          elem.style.fill = defaultStateColor;
          elem.onclick = e => {
            e.stopPropagation();
            // window.location = withPrefix(`/reforms/${e.target.id}/`);
            typeof window !== "undefined" && window.gtag && window.gtag('event', 'select_content',{
              content_type: `usa-map-state_${props.mapKey}`,
              item_id: `${state}`
            });
            props.setSelectedStateFromMap(state === props.SelectedStateFromMap ? null : state);
          };
          elem.onmouseenter = e => {
            // setHoveredState(state);
            e.target.style.fill = "orange";
          };
          elem.onmouseleave = e => {
            // setHoveredState(null);
            e.target.style.fill = defaultStateColor;
          };
        }
      }
    };
    updateMap();
  }, [props]);


  return (
    <div className="usa-map" key={props.mapKey}>
      {
        !props.hideInfoPanel &&
        <div className="usa-map-info">
          <div className="usa-map-description">
            <div className="usa-map-title">
              {props.mapTitle}
            </div>
            { !props.hideStateSelect && <USAMapStateSelect /> }
            <div className="usa-map-subtitle">
              {props.mapSubtitle}
            </div>
          </div>
            {
              !props.hideStateButton &&
              props.SelectedStateFromMap &&
              <button
                className={"usa-map-state-button"}
                onClick={() => window.location = withPrefix(`/reforms/${props.SelectedStateFromMap}/`)}>
                <strong>
                  Go to the full {statenames[props.SelectedStateFromMap]} page
                </strong>
              </button>
            }
        </div>
      }
      <div className="usa-map-view">
        <div className="usa-map-legend">
          <span>{props.mapLegendTitle}</span>
          <div className="values">
            {
              props.legend &&
              Object.keys(props.legend).map((key) => {
                return <div key={key} className="legend-item">
                    <USAMapLegendColor key={key} color={props.legend[key]} />
                    {key}
                  </div>
              })
            }
          </div>
        </div>
        <USA />
      </div>
    </div>
  );
};

export default USAMap;